import clsx from "clsx";
import { HeaderUserMenu } from "../../partials";
import { UserAvatar } from "../../partials/layout/header-menus/UserAvatar";
import { useEffect, useState } from "react";
import axios from "axios";

const itemClass = "pl-5";
// const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = "symbol-35px";

const Navbar = () => {
  const [lightImage, setLightImage] = useState<any>(null);
  const [darkImage, setDarkImage] = useState<any>(null);
  useEffect(() => {
    axios
      .get("/assets/client_logo?theme=light")
      .then((res) => {
        setLightImage(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    axios
      .get("/assets/client_logo?theme=dark")
      .then((res) => {
        setDarkImage(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="app-navbar flex-shrink-0">
      <div className={clsx("app-navbar-item align-items-stretch", itemClass)}>{/* <Search /> */}</div>

      {lightImage && lightImage !== "" && (
        <div className="d-flex align-items-center">
          <div className="h-50px d-flex align-items-center pr-5 border-end border-gray-500">
            <img alt="Logo" src={lightImage} className="mh-15px mh-sm-25px app-sidebar-logo-default theme-light-show" />
            {darkImage && darkImage !== "" ? (
              <img alt="Logo" src={darkImage} className="mh-15px mh-sm-25px app-sidebar-logo-default theme-dark-show" />
            ) : (
              <img
                alt="Logo"
                src={lightImage}
                className="mh-15px mh-sm-25px app-sidebar-logo-default theme-light-show"
              />
            )}
          </div>
        </div>
      )}

      <div role="menu" className={clsx("app-navbar-item", itemClass)}>
        <div
          role="menuitem"
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <div className="overflow-hidden rounded">
            <UserAvatar />
          </div>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Navbar };
